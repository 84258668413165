<template>
  <div class="sign-up-wrap purple-bg py-9">
    <span class="stepProgress" :class="{ 'last': step === 2 }"></span>
    <div class="small-container">
      <div class="sign-up-wrap-ms-form">
        <form @submit.prevent="handleSubmit" @input="validateForm">
          <h2 class="h2-style">{{ __t('sign-up-profile.title') }}</h2>
          <transition name="fade">
            <div class="onboarding-step-1 pt-7" v-if="step === 1">
              <div class="form-group">
                <label for="name">{{ __t('sign-up-profile.fullname') }}</label>
                <input type="text"
                       class="form-control"
                       required
                       :class="{ 'is-invalid': validate.full_name === false }"
                       v-model="profileData.full_name"
                       id="name"
                       @input="validateField(profileData.full_name, 'full_name')"
                       aria-describedby="emailHelp">
                <small>{{ __t('sign-up-profile.fullname-description') }}</small>
              </div>

              <div class="form-group">
                <label for=
                "username">{{ __t('sign-up-profile.username') }}</label>
                <input type="text"
                       class="form-control"
                       id="username"
                       maxlength="36"
                       @input="validateUsername(profileData.username)"
                       :class="{ 'is-invalid': validate.username === false }"
                       required
                       v-model="profileData.username"
                       aria-describedby="emailHelp">
                <small class="form-text invalid-feedback" v-if="validate.usernameUsed">
                  The username has already been taken.
                </small>
                <small class="form-text invalid-feedback" v-if="!validate.usernameUsed"
                       v-text="(profileData.username && profileData.username.length > 36) ? 'Max 36 characters' : 'Username cannot be empty.'"></small>
                <small>{{ __t('sign-up-profile.username-description') }}</small>
              </div>

              <div class="form-group">
                <label for="subline">{{ __t('sign-up-profile.subline') }} *</label>
                <input type="text"
                       class="form-control"
                       :class="{ 'is-invalid': validate.subline === false }"
                       v-model="profileData.subline"
                       required id="subline"
                       @input="validateField(profileData.subline, 'subline')"
                       maxlength="36"
                       aria-describedby="emailHelp">
                <small class="form-text invalid-feedback" v-text="'Subline cannot be empty.'"></small>
                <small class="d-flex justify-content-between">
                  <span>{{ __t('sign-up-profile.subline-description') }}</span>
                  <span v-text="(((profileData.subline && profileData.subline.length) || '0') + '/' + '36')"></span>
                </small>
              </div>
            </div>
          </transition>

          <transition name="fade">
            <div class="onboarding-step-2 pt-5" v-if="step === 2">
              <div class="onboarding-step-2">
                <div class="form-group">
                  <label for="name">{{ __t('sign-up-profile.birthdate') }}</label>
                  <input-masked
                    type="text"
                    mask="11/11/1111"
                    class="is-invalid"
                    placeholder="DD/MM/YYYY"
                    :placeholder-char="'DD/MM/YYYY'.split('')"
                    @input="validateField(profileData.birthdate, 'birthdate')"
                    v-model="profileData.birthdate"></input-masked>
                    <small class="form-text invalid-feedback" v-if="profileData.birthdate !== 'DD/MM/YYYY'" v-text="(profileData.birthdate.length > 0 && !validate.birthdate) ? 'Date of Birth is not valid' : ''">
                    </small>
                    <small class="form-text invalid-feedback"
                    v-text="(profileData.birthdate === 'DD/MM/YYYY') ? 'Date of Birth cannot be empty.' : ''"></small>
                    <small class="form-text invalid-feedback"
                    v-text="(!isAgeRangeValid && validate.birthdate) ? 'Age must be equal or greater then 16' : ''"></small>
                </div>

                <!-- <div class="form-group date-of-birth-wrap">
                  <h5 class="h5-style">{{ __t('sign-up-profile.birthdate') }}</h5>
                  <datepicker
                    v-model="profileData.birthdate"
                    :disabled-dates="{ from: disabledDates }"
                    placeholder="DD / MM / YYYY"
                    :format="dateFormatter"></datepicker>
                </div> -->
                <div class="form-group gender">
                  <h5 class="h5-style">{{ __t('sign-up-profile.gender') }}</h5>
                  <div class="checkboxes-group">
                    <div class="checkbox-button pb-3" v-for="(gender, i) in base.profileGender" :key="'gen-key-' + i">
                      <input type="radio" name="gender" v-model="profileData.gender" :id="'rtype-id-' + i" :value="i">
                      <label :for="'rtype-id-' + i" v-text="gender"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <div class="yellow-button">
            <button type="submit" class="btn-default" :disabled="!valid">{{ __t('sign-up-profile.submit') }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
// import Datepicker from 'vuejs-datepicker'
import InputMasked from '@aymkdn/vue-input-masked'
import { sub, format, isValid, differenceInYears, parse } from 'date-fns'
export default {
  components: {
    // Datepicker
    InputMasked
  },
  data () {
    return {
      step: 1,
      valid: false,
      profileData: {
        full_name: null,
        username: null,
        subline: null,
        birthdate: '',
        gender: null
      },
      validate: {
        full_name: null,
        username: null,
        usernameUsed: null,
        subline: null,
        gender: null,
        birthdate: null
      },
      isAgeRangeValid: true
    }
  },
  computed: {
    ...mapState('auth', ['base'])
  },
  watch: {
    'profileData.gender' (nv, ov) {
      nv && this.validateForm()
    },
    'profileData.birthdate' (nv, ov) {
      nv && this.validateForm()
    }

  },
  methods: {
    ...mapActions('auth', ['updateProfile', 'validateUsernameExist']),
    validateField (data, property) {
      if (property === 'birthdate') {
        const date = parse(data, 'dd/MM/yyyy', new Date())
        console.log('birthdate', isValid(date))
        this.validate.birthdate = isValid(date)
        this.checkAge(data)
      } else {
        this.validate[property] = data && data.length > 1
        if (!this.validate[property]) this.valid = false
      }
    },
    validateUsername (username) {
      let data = username && username.trim()
      this.validate.username = (data && data !== '' && data.length < 37)
      if (!this.validate.username) this.valid = false
      setTimeout(() => {
        if (this.validate.username) {
          this.validateUsernameExist(data).then(response => {
            if (response && response.status === 422) {
              this.validate.username = false
              this.validate.usernameUsed = true
              this.valid = false
            } else {
              this.validate.username = true
              this.validate.usernameUsed = false
            }
          })
        }
      }, 500)
    },
    handleSubmit () {
      this.validateUsername(this.profileData.username)
      this.validateField(this.profileData.full_name, 'full_name')
      this.validateField(this.profileData.subline, 'subline')
      if (this.valid) {
        if (this.step === 1) {
          this.step = 2
          this.valid = false
        } else {
          this.updateProfile(this.profileData).then(resp => {
            this.validate.username = true
            this.validate.usernameUsed = false

            if (resp.errors) {
              if (resp.errors.username) {
                this.validate.username = false
                this.validate.usernameUsed = true
              }
              this.step = 1
              this.valid = false
            } else {
              resp && this.$router.push({ name: 'StyleQuiz' })
            }
          })
        }
      } else {
        this.step = 1
        this.valid = false
      }
    },
    validateForm () {
      this.valid = this.step === 1
        ? (this.validate.full_name && this.validate.username && this.validate.subline)
        : this.validate.full_name && this.validate.username && this.validate.subline && this.validate.birthdate && this.profileData.gender && this.profileData.birthdate && this.isAgeRangeValid
    },
    checkAge (data) {
      console.log('age', differenceInYears(new Date(), parse(data, 'dd/MM/yyyy', new Date())))
      let age = differenceInYears(new Date(), parse(data, 'dd/MM/yyyy', new Date()))
      this.isAgeRangeValid = age >= 16
    }
  },
  created () {

  },
  mounted () {
    this.profileData.username = this.$store.state.auth.user.username
    this.validate.birthdate = true
    this.profileData.birthdate = format(sub(new Date(), {
      years: 16
    }), 'dd/MM/yyyy')
    this.validateUsername(this.profileData.username)
  }
}
</script>
